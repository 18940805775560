import './style.css'
import Sidebar from '../Sidebar'
import Select from '../Select'
import History from '../history'
import '../../css/connect-wallet.css'
import '../../css/f-select.css'
import { useState, useEffect } from 'react'
import { getwalletdatat } from './api'
import ConnectHeaderConnect from '../walletConnectheader/index'
import Auction from '../../web3handler/contractHandler/auctions.js'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


interface tokenData {
    contract_address: string,
    tokenBalance: string,
    tokenDecimal: Number,
    tokenLogo: string,
    tokenName: string


}
interface tokenOption {
    name: string,
    image: string
}
const Home = ({ walletdata, disconnectWallet, walletConnect }: any) => {
    //   State Defination
    const [currentTab, setCurrentTab] = useState<String>('Send')
    const [currentToken, setCurrentToken] = useState<tokenData[]>([])
    const [tokens, setTokens] = useState<tokenOption[]>([])
    const [loading, setLoading] = useState<Boolean>(false)
    const [selectedToken, setSelectedToken] = useState<tokenData | undefined>()
    const [recipet, setRecipiet] = useState<String>('')
    const [inputToken, setToken] = useState<String>('')
    const [sendLoading, setSendLoading] = useState(false)
    const [currentNetwork, setCurrentNetwork] = useState('Binance')

    // End of State Defination



    // USE eFFECT Defination



    useEffect(() => {

        getWalletToken()

        const { chainId } = walletdata
        if (chainId == 56 || chainId == 97) {
            setCurrentNetwork('Binance')
        }
        else if (chainId == 80001 || chainId == 137) {
            setCurrentNetwork('Polygon')
        }
        else {
            setCurrentNetwork('Etherium')
        }

    }, [])


    // End of Use effect Part



    // User variable and configuration defination part
    const options = [
        {
            name: "Etherium",
            image: "https://mwancloud.com/mw-najeeb-work/Connect-wallet/images/icons/eth-coin.svg",
            value: 1
        },
        {
            name: 'Polygon',
            image: "https://mwancloud.com/mw-najeeb-work/Connect-wallet/images/icons/polygon.png",
            value: 137
        },
        {
            name: 'Binance',
            image: "https://mwancloud.com/mw-najeeb-work/Connect-wallet/images/icons/polygon.png",
            value: 56
        }
    ]



    // End of User variable Decleration



    // User Define Function

    const onChanageTab = (value: String) => {
        setCurrentTab(value)

    }

    const getWalletToken = () => {
        setLoading(true)
        getwalletdatat?.getWalletToken(walletdata?.chainId, walletdata?.accounts, (err: any, data: any) => {
            console.log(err)
            console.log(data?.data?.data)
            if (err == null) {
                const newdata = data?.data?.data?.reverse()
                setCurrentToken([...newdata])
                let tokensdata = newdata?.map((item: tokenData) => {
                    return { name: item?.tokenName, img: item?.tokenLogo, image: 'https://mwancloud.com/mw-najeeb-work/Connect-wallet/images/icons/eth-coin.svg', ...item }

                })
                setSelectedToken(tokensdata[0])
                setTokens([...tokensdata])
            } else {
                setCurrentToken([])
            }
            setLoading(false)
        })
    }


    const selectNetwork = (item: any) => {
        if (walletConnect) {
            walletConnect?.killSession();
        }

    }
    const selectToken = (item: any) => {
        console.log(item)
        setSelectedToken(item)

    }

    const sendTokens = async () => {
        setSendLoading(true)
        const recipient = (document?.getElementById("recipient") as HTMLInputElement)?.value
        const token = (document?.getElementById("token") as HTMLInputElement)?.value

        const { tokenDecimal, tokenName, contract_address } = selectedToken as tokenData

        console.log(tokenDecimal)
        console.log(walletConnect)
        const tokens = ['BNB', 'BNBT', 'ETH', 'MATIC']
        if (!tokens?.includes(tokenName?.toUpperCase())) {
            await Auction.sendTokenWithWalletConnect(walletConnect, token, walletdata?.accounts, recipient, contract_address, tokenDecimal, tokenName, token, walletdata?.chainId, (err: any, data: any) => {
                if (err) {
                    console.log(err)
                    setLoading(false)
                    toast.error(err?.message || 'Something Went Wrong')

                }
                else {
                    console.log(data)
                    toast.success("Your Token Transfer successfully Completed")
                    getWalletToken()

                }
                setSendLoading(false)
                // document.getElementById("reciveraddress")?.innerText = ""
                // document.getElementById("amount")?.innerText = ""
            })
                .catch((err: any) => {
                    console.log(err)
                    setSendLoading(false)
                    toast.error(err?.message || 'Something Went Wrong')





                });
        } else {
            await Auction.sendCustomTransaction(walletConnect, recipient, token, tokenName, walletdata?.chainId, (err: any, data: any) => {
                if (err) {
                    console.log(err)
                    setLoading(false)
                    toast.error(err?.message || 'Something Went Wrong')

                }
                else {
                    console.log(data)
                    toast.success("Your Token Transfer successfully Completed")
                    getWalletToken()

                }
                setSendLoading(false)
                // document.getElementById("reciveraddress")?.innerText = ""
                // document.getElementById("amount")?.innerText = ""
            })
                .catch((err: any) => {
                    console.log(err)
                    setSendLoading(false)
                    toast.error(err?.message || 'Something Went Wrong')

                });
        }

    }

    // End of User Defination Function


    return (
        <>
            <section className="main-body position-relative">

                <div className="main-content-container">
                    <ToastContainer />

                    <Sidebar currentTab={currentTab} onChanageTab={onChanageTab} />

                    {currentTab == 'Send' ? <><div className="content-header d-flex align-items-center justify-content-between">
                        <div className="wallet-status d-none d-lg-flex align-items-center">
                            <span></span> Connected
                        </div>
                        <button id="menu-open" className="mneu-btn px-2 py-1 ms-3 border rounded bg-light">
                            <i className="bi bi-list text-dark"></i>
                        </button>
                        <div className="wallet-status d-flex d-lg-none align-items-center">
                            <span></span> Connected
                        </div>

                        <ConnectHeaderConnect walletdata={walletdata} disconnectWallet={disconnectWallet} />
                    </div>

                        <div className="content-body">
                            <div className="row">
                                <div className="col-lg-8 col-xxl-9 mb-3 mb-lg-0">
                                    <div className="send-form-sec">
                                        <h1>Send Tokens</h1>
                                        {loading == true ? <div className='loading'><div className="custom-loader"></div></div> : <div className="content-card">
                                            <div className="form-group f-group">
                                                <Select options={options} selectElement={selectNetwork} selectName={currentNetwork} />
                                            </div>
                                            <div className="form-group">
                                                <label>Recipient</label>
                                                <input type="text" className="form-control" placeholder="Enter 0x Address" id="recipient" onChange={(e) => {
                                                    setRecipiet((e?.target as HTMLInputElement).value)

                                                }} />
                                            </div>
                                            <div className="form-group row">
                                                <label>Asset</label>
                                                <div className="col-md-6 mb-3 mb-md-0">
                                                    <input type="text" className="form-control" placeholder="0.0" id="token" onChange={(e) => {
                                                        setToken((e?.target as HTMLInputElement).value)
                                                    }} />
                                                </div>
                                                <div className="col-md-6">
                                                    <Select options={tokens} selectElement={selectToken} selectName={tokens[0]?.name} />
                                                </div>
                                            </div>
                                            <div className="form-group mb-0">
                                                {inputToken == '' || recipet == '' ? <button className="btn btn-primary d-block w-100" onClick={() => {
                                                    const recipient = (document?.getElementById("recipient") as HTMLInputElement)?.value
                                                    const token = (document?.getElementById("token") as HTMLInputElement)?.value
                                                    if (recipient?.trim() == '') {
                                                        toast.error("Please Enter a Correct Address")
                                                        return
                                                    }
                                                    if (token?.trim() == '') {
                                                        toast.error("Please Enter Amount")
                                                        return
                                                    }
                                                }}>Send</button> : <button className="btn btn-primary d-block w-100" data-bs-toggle="modal" data-bs-target={`${"#send-confirmation"}`}
                                                >Send</button>}
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                                {<div className="col-lg-4 col-xxl-3">
                                    <div className="token-section">
                                        {loading == true ? <div className='token-body'></div> : <>
                                            <div className="token-header">
                                                <div className="account-balance d-flex align-items-center">
                                                    <img className="me-3" width="44" height="44" src="https://mwancloud.com/mw-najeeb-work/Connect-wallet/images/icons/eth-coin.svg" alt="coin-icon" />
                                                    <div className="account">
                                                        <p>Account Balance</p>
                                                        <div className="amount-info">
                                                            {currentToken[0]?.tokenBalance == undefined ? '0.0' : parseFloat(currentToken[0]?.tokenBalance)?.toFixed(3)} {currentToken[0]?.tokenName}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='token-body'>
                                                <h2>Tokens</h2>
                                                {currentToken?.length > 1 ? <ul className="nav token-nav flex-column">
                                                    {
                                                        currentToken?.slice(1, currentToken?.length)?.map((item: tokenData) => {
                                                            return <li className="nav-item mb-2">
                                                                <div className="d-flex align-items-center">
                                                                    <img src='https://mwancloud.com/mw-najeeb-work/Connect-wallet/images/icons/eth-coin.svg' alt="token-ic" />
                                                                    <span>{parseFloat(item?.tokenBalance)?.toFixed(2)} {item?.tokenName}</span>
                                                                </div>
                                                            </li>
                                                        })
                                                    }

                                                </ul> :
                                                    <div className="empty-token mt-3 px-3 py-5 text-center">
                                                        <img width="70" height="70" className="mb-4" src="https://mwancloud.com/mw-najeeb-work/Connect-wallet/images/icons/empty-token.svg" alt="icon" />
                                                        <div className="no-token-hd mb-1">
                                                            No Tokens are Avaiable
                                                        </div>
                                                    </div>}
                                            </div>
                                        </>}

                                    </div>
                                </div>}
                            </div>

                        </div> </> : <History walletdata={walletdata} disconnectWallet={disconnectWallet} />}

                </div>

            </section>

            {/* <!--Connect wallet scan Downlaod Modal-- > */}
            <div className="modal connect-modal send-modal fade" id="send-confirmation" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <div className="send-icon">
                                <img width="60" height="60" src="https://mwancloud.com/mw-najeeb-work/Connect-wallet/images/icons/paper-plane.svg" alt="paper-plan" />
                            </div>
                        </div>
                        <div className="modal-body text-center">
                            <h1>Confirmation</h1>
                            <p className="top">Please verify the following transaction</p>
                            <div className="modal-inner-content qr-content-sec">
                                <p>
                                    Recipient:
                                </p>
                                <p className="wallet-address">
                                    {recipet}
                                </p>
                                <p className="mb-2">You are sending:</p>
                                <div className="sending-amount">
                                    <ul className="nav mb-4 mb-md-5 align-items-center">
                                        <li className="nav-item pe-2">
                                            <span className="amount">{inputToken}</span>
                                        </li>
                                        <li className="nav-item pe-2">
                                            <div className="coin-name d-flex align-items-center">
                                                <img width="17" height="17" src="https://mwancloud.com/mw-najeeb-work/Connect-wallet/images/icons/eth-coin.svg" alt="coin" />
                                                <span className="coin-n">{selectedToken?.tokenName}</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="confirmation-sec">
                                    <button className="btn btn-cancel mx-2" data-bs-dismiss="modal" aria-label="Close">
                                        Cancel
                                    </button>
                                    {<button className="btn btn-primary mx-2" onClick={sendTokens}>
                                        {sendLoading == true ? <div className="custom-loader1"></div> : 'Send'}
                                    </button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home
