import React from 'react'

const ConnectHeaderConnect = ({ walletdata, disconnectWallet }: any) => {
    return (
        <ul className="nav align-items-center top-h-nav px-3 px-lg-4">
            <li className="nav-item px-1 px-lg-2">
                <div className="wallet-info d-flex align-items-center">
                    <img src="https://mwancloud.com/mw-najeeb-work/Connect-wallet/images/icons/wallet-blue.svg" alt="wallet" /> <span>{walletdata?.accounts}</span>
                </div>
            </li>
            <li className="nav-item px-1 px-lg-2">
                <a href="#" className="btn btn-disconnect d-flex align-items-center justify-content-center" onClick={(e) => {
                    e?.preventDefault()
                    disconnectWallet()
                }}>
                    <img src="https://mwancloud.com/mw-najeeb-work/Connect-wallet/images/icons/disconnct-ic.svg" alt="disconnct-icon" /> Disconnect
                </a>
            </li>
        </ul>
    )
}

export default ConnectHeaderConnect
