import axios from '../../axios-configuration/index'
export const getwalletdatat: any = {
    getWalletToken: (network: Number, address: String, cb: any) => {

        axios.post(`/getAllCustomToken?chainId=${network}&walletAddress=${address}`, {
        },
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        )
            .then((data) => {

                cb(null, data)
            }).catch(err => {
                cb(err, null)
            })
    },
    getTransactionHistory: (network: Number, address: String, cb: any) => {

        axios.post(`/getAllTransactionForAddress?chainId=${network}&walletAddress=${address}`, {
        },
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        )
            .then((data) => {
                cb(null, data)
            }).catch(err => {
                cb(err, null)
            })
    }

}

