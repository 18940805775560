import ConnectHeaderConnect from "../walletConnectheader"
import { useEffect, useState } from 'react';
import { utils } from "../../utils/utility";
import { getwalletdatat } from '../Home/api'
import Offcanvas from 'react-bootstrap/Offcanvas';
const History = ({ walletdata, disconnectWallet }: any) => {
    const [history, setHistory] = useState<any>({})
    const [loading, setLoading] = useState<Boolean>(false)
    const [show, setShow] = useState(false)
    useEffect(() => {
        getWalletHistory()

    }, [])


    // User Defination Function

    const getWalletHistory = () => {
        setLoading(true)
        getwalletdatat?.getTransactionHistory(97, walletdata?.accounts, (err: any, data: any) => {
            var groupyBy: any = {}
            if (data?.data?.data != undefined) {
                data?.data?.data?.items?.map((item: any) => {
                    let { transactiondate, transactionTime } = utils?.convertTimeIntoLocal(item?.block_signed_at)
                    // let block_signed_at = item?.block_signed_at?.split('T')[0]
                    if (groupyBy?.hasOwnProperty(transactiondate)) {
                        groupyBy[transactiondate] = [...groupyBy[transactiondate], { ...item, transactionTime: transactionTime }]
                    }
                    else {
                        groupyBy[transactiondate] = [{ ...item, transactionTime: transactionTime }]
                    }
                })
                setHistory(groupyBy)
            }
            setLoading(false)
            console.log(groupyBy)
        })

    }


    // End Of User Defination



    return (

        <>
            <Offcanvas show={show} className="transaction-detail" placement='end' onHide={() => {
                setShow(false)
            }}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Transaction Detail</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="hash-detail">
                        <h6>Transaction Hash:</h6>
                        <p>0xCad6c00930c9DcE86aDa5C3f149348c0365be8Ab</p>
                    </div>
                    <div className="amount-send">
                        <h6>Amount Sent:</h6>
                        <div className="coin-details">
                            <span>0.57</span> <span className="token"> <img width="17" height="17" src="https://mwancloud.com/mw-najeeb-work/Connect-wallet/images/icons/eth-coin.svg" alt="token-ic" /> Eth</span>
                        </div>
                    </div>
                    <div className="all-detail">
                        <div className="row">
                            <div className="col-sm-4">
                                <span className="title">To Address:</span>
                            </div>
                            <div className="col-sm-8">
                                <span className="descp">0xCad6c00930c9DcE86aDa5C3f149348c0365be8Ab</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4">
                                <span className="title">From Address:</span>
                            </div>
                            <div className="col-sm-8">
                                <span className="descp">0xCad6c00930c9DcE86aDa5C3f149348c0365be8Ab</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4">
                                <span className="title">Amount Sent:</span>
                            </div>
                            <div className="col-sm-8">
                                <span className="descp">0.552 ETH</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4">
                                <span className="title">Account Balance:</span>
                            </div>
                            <div className="col-sm-8">
                                <span className="descp">0.5</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4">
                                <span className="title">Coin:</span>
                            </div>
                            <div className="col-sm-8">
                                <span className="descp">ROSPSTEN ETH</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4">
                                <span className="title">Network:</span>
                            </div>
                            <div className="col-sm-8">
                                <span className="descp">ROSPSTEN ETH by infura</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4">
                                <span className="title">Gass Limit:</span>
                            </div>
                            <div className="col-sm-8">
                                <span className="descp">21000</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4">
                                <span className="title">Gass Limit:</span>
                            </div>
                            <div className="col-sm-8">
                                <span className="descp">41 GWEI (0.000000041 ETH)</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4">
                                <span className="title">Max TX Fee:</span>
                            </div>
                            <div className="col-sm-8">
                                <span className="descp">0.000861 ETH (861000 GWEI)</span>
                            </div>
                        </div>
                    </div>

                </Offcanvas.Body>
            </Offcanvas>
            <div className="content-header d-flex align-items-center justify-content-between">
                <div className="wallet-status d-none d-lg-flex align-items-center">
                    <span></span> Connected
                </div>
                <button id="menu-open" className="mneu-btn px-2 py-1 ms-3 border rounded bg-light">
                    <i className="bi bi-list text-dark"></i>
                </button>
                <div className="wallet-status d-flex d-lg-none align-items-center">
                    <span></span> Connected
                </div>
                <ConnectHeaderConnect walletdata={walletdata} disconnectWallet={disconnectWallet} />
            </div>

            <div className="content-body history-body">
                <h1>History</h1>

                {loading == true ? <div className='loading'><div className="custom-loader"></div></div> : <div className="history-content">
                    {
                        Object.keys(history)?.map((item: any) => {
                            return <div className="histroy-day">
                                <h2 className="history-date my-4 mt-lg-5 mb-lg-2">
                                    {item}
                                </h2>

                                {history[item]?.map((tran: any) => {
                                    return < div className="row">
                                        <div className="col-lg-2 mb-3 mb-lg-0">
                                            <div className="h-content time-c">
                                                <p>Send</p>
                                                <p className="time">{tran?.transactionTime}</p>
                                            </div>
                                        </div>

                                        <div className="col-lg-5 mb-3 mb-lg-0">
                                            <div className="h-content">
                                                <p>Transaction Hash</p>
                                                <div className="transaction-c t-hash" onClick={(e) => {
                                                    e.preventDefault()
                                                    setShow(true)
                                                }}>
                                                    <a href="" target="_blank">
                                                        {tran?.tx_hash?.slice(0, 25)}...{tran?.tx_hash?.slice(45, tran?.tx_hash?.length)}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-lg-3 mb-3 mb-lg-0">
                                            <div className="h-content recipient-c">
                                                <p>Recipient</p>
                                                <div className="transaction-c">
                                                    {tran?.to_address?.slice(0, 20)
                                                    }...{tran?.to_address?.slice(28, tran?.to_address?.length)
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-2 mb-3 mb-lg-0">
                                            <div className="h-content recipient-c">
                                                <p>Amount</p>
                                                <div className="amount-c">
                                                    0.554 ETH
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })
                                }


                            </div>
                        })
                    }
                </div>}

            </div>
        </>
    )
}

export default History
