
import './App.css';
import Home from './components/Home';
import ConnectWallet from './components/walletconnect';
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";
import Web3Modal from "web3modal"
import WalletConnectProvider from "@walletconnect/web3-provider";
import { useEffect, useState } from 'react'
function App() {

  //  State Declaration 
  const [walletConnect, setWalletConnect] = useState(null)
  const [walletdata, setWalletdata] = useState({})

  // End of State Declaration


  // useEffect 

  useEffect(() => {
    const connectwallet = localStorage.getItem("walletconnect")
    if (connectwallet != undefined || connectwallet != null) {
      ExWalletConnect()
    }
  }, [])

  // End of Use Effect


  //  User defined Object and Variables


  const Provider = {
    type: String,
    chain: 'polygon',
    provider: Object,
    ERC20: [],
    explorer: String,
    sockets: String,
    web3Mod: Object

  }
  const BinanceProvider = {
    type: String,
    chain: 'polygon',
    provider: Object,
    ERC20: [],
    explorer: String,
    sockets: String,
    web3Mod: Object

  }
  const ethProvider = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        infuraId: undefined,
        rpc: {
          // 1: `${process.env.REACT_APP_RPC}`,
          [parseInt(`${process.env.REACT_APP_CHAINID_ETH}`)]: `${process.env.REACT_APP_ETH_RPC}`
        },
        supportedChainIds: [`${process.env.REACT_APP_ETH_RPC}`],
        chainId: `${process.env.REACT_APP_ETH_RPC}`,

        pollingInterval: 20000

      }
    },
  }

  const mumbaiProvider = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        infuraId: undefined,
        rpc: {
          // 1: `${process.env.REACT_APP_RPC}`,
          [parseInt(`${process.env.REACT_APP_CHAINID_POLY}`)]: `${process.env.REACT_APP_POLY_RPC}`
        },
        supportedChainIds: [`${process.env.REACT_APP_POLY_RPC}`],
        chainId: `${process.env.REACT_APP_POLY_RPC}`,

        pollingInterval: 20000

      }
    },
  }
  const binanceProvider = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        infuraId: undefined,
        rpc: {
          // 1: `${process.env.REACT_APP_RPC}`,
          [parseInt(`${process.env.REACT_APP_CHAINID_BNB}`)]: `${process.env.REACT_APP_BNB_PRC}`
        },
        supportedChainIds: [`${process.env.REACT_APP_BNB_PRC}`],
        chainId: `${process.env.REACT_APP_BNB_PRC}`,

        pollingInterval: 20000

      }
    },
  }


  // End of User Variable Defined

  // User Define Function 

  const disconnectWallet = () => {
    if (walletConnect) {
      walletConnect?.killSession();
    }
    setWalletConnect(null)
    localStorage.removeItem("walletconnect")
  }
  const ExWalletConnect = async () => {

    const bridge = "https://bridge.walletconnect.org";



    const provider = binanceProvider
    const connector = new WalletConnect({ bridge, qrcodeModal: QRCodeModal });

    // console.log(providerOptions)

    const web3Modal = new Web3Modal({
      provider
    })
    BinanceProvider.provider = connector
    BinanceProvider.web3Mod = web3Modal

    console.log(BinanceProvider)

    // check if already connected
    if (!connector.connected) {
      // create new session

      await connector.createSession();
    }

    connector.on("session_update", async (error, payload) => {
      console.log(`connector.on("session_update")`);

      if (error) {
        throw error;
      }
      setWalletConnect(connector)
      const { chainId, accounts } = payload.params[0];
      console.log(accounts)
      setWalletdata({ chainId: chainId, accounts: accounts[0] })
      // setWalletconnectloaded(false)
    });

    connector.on("connect", (error, payload) => {
      console.log(`connector.on("connect")`);

      if (error) {
        throw error;
      }

      setWalletConnect(connector)
      const { chainId, accounts } = payload.params[0];
      console.log(accounts)
      setWalletdata({ chainId: chainId, accounts: accounts[0] })

    });


    connector.on("disconnect", (error, payload) => {
      console.log(`connector.on("disconnect")`);
      if (error) {
        throw error;
      }
      if (walletConnect) {
        walletConnect?.killSession();
      }
      setWalletConnect(null)


    });


    if (connector.connected) {
      const { chainId, accounts } = connector;
      const address = accounts[0];
      setWalletConnect(connector)
      setWalletdata({ chainId: chainId, accounts: accounts[0] })

    }

  }


  // End of User Define 
  return (
    <div className="App">
      {
        walletConnect == null ?

          <ConnectWallet ExWalletConnect={ExWalletConnect} walletdata={walletdata} /> :
          <Home walletdata={walletdata} disconnectWallet={disconnectWallet} walletConnect={walletConnect} />
      }
    </div>
  );
}

export default App;
