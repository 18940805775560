
import { walletProps } from '../../configuration/token-interface'
import { useState } from 'react';

const Sidebar = ({ currentTab, onChanageTab }: walletProps) => {

    // Use State Defination

    

    // End of State Defination





    //  UseEffect Part


    //  End of Use Part



    // Use defined Function




    //  End of User Define Functon



    return (
        <>
            <div className="sidebar-container">
                {/* {currentTab == 'Send' ?  */}
                <div className={`sidebar-header text-center  position-relative`}>
                    <div className="company-logo mb-3">
                        <img src="	https://mwancloud.com/mw-najeeb-work/Connect-wallet/images/logo/mwanconnect-loog.svg" alt="logo" />
                    </div>
                    <div className="company-title mb-4 text-center">
                        <h1>Welcome to Mwan Connect</h1>
                        
                    </div>
                    {/* <button className="btn btn-primary rounded-pill mb-3 w-100" data-bs-toggle="modal" data-bs-target="#connect-wallet-scan">
                        <span className="icon-connect me-2"></span> Connect Wallet
                    </button> */}
                    <button className="close-menu d-md-none">
                        <i className="bi bi-x"></i>
                    </button>
                </div> :

                {/* <div className={`sidebar-header-history ${currentTab == 'History' ? 'active' : ''}`}>
                    <div className="Add-detail">
                        <div className="add-title mb-1">Public Address</div>
                        <div className="add">
                            muuVdm...v4jfisuUQF
                        </div>
                    </div>
                    <div className="u-amount">
                        $113.98
                    </div>
                </div> */}
                {/* } */}

                <div className="sidebar-navigation">
                    <ul className="nav flex-column">
                        <li className="nav-item">
                            <a href="#" className={`sidebar-link ${currentTab == 'Send' ? 'active' : ''}`} onClick={(e) => {
                                e?.preventDefault()
                                onChanageTab('Send')
                            }}>
                                <span className="icon-send me-3"></span> Send
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="#" className={`sidebar-link ${currentTab == 'History' ? 'active' : ''}`} onClick={(e) => {
                                e?.preventDefault()
                                onChanageTab('History')
                            }}>
                                <span className="icon-history me-3"></span> History
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="sidebar-link  <?php if($currentPage =='setting'){echo 'active';}?>">
                                <span className="icon-setting me-3"></span> Setting
                            </a>
                        </li>
                    </ul>
                </div>

                <div className="app-button-sec">
                    <button className="btn btn-download d-flex align-items-center justify-content-center" data-bs-toggle="modal" data-bs-target="#app-download">
                        <span>
                            <img src="https://mwancloud.com/mw-najeeb-work/Connect-wallet/images/icons/ic_google.svg" />
                        </span>
                        <span className="b-cont">
                            <span className="available">Avaiable on </span> <br />
                            Google Play
                        </span>
                    </button>

                    <button className="btn btn-download d-flex align-items-center justify-content-center" data-bs-toggle="modal" data-bs-target="#app-download">
                        <span>
                            <img src="https://mwancloud.com/mw-najeeb-work/Connect-wallet/images/icons/ic_apple.svg" />
                        </span>
                        <span className="b-cont">
                            <span className="available">Avaiable on </span> <br />
                            App Store
                        </span>
                    </button>
                </div>

            </div>


            {/* <!--App Downlaod Modal-- > */}
            <div className="modal connect-modal fade" id="app-download" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <i className="bi bi-x"></i>
                            </button>
                        </div>
                        <div className="modal-body text-center">
                            <h1>Download our App</h1>
                            <div className="qr-scan-img pt-3">
                                <img width="217" height="217" src="https://mwancloud.com/mw-najeeb-work/Connect-wallet/images/qr-img.svg" alt="qr-scan" />
                            </div>
                            <div className="modal-inner-content qr-content-sec">
                                <p>
                                    Scan QR code to get download link specifically for your mobile platform
                                </p>
                            </div>

                            <div className="download-link d-none">
                                <ul className="nav justify-content-center py-3">
                                    <li className="nav-item">
                                        <a href="#" className="px-2">
                                            <img width="142" height="43" src="https://mwancloud.com/mw-najeeb-work/Connect-wallet/images/icons/ic_apple.svg" alt="aap-link-btn" />
                                        </a>
                                    </li>
                                    <li className="nav-item px-2">
                                        <a href="#">
                                            <img width="142" height="43" src="https://mwancloud.com/mw-najeeb-work/Connect-wallet/images/icons/ic_google.svg" alt="aap-link-btn" />
                                        </a>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/* <!--Connect wallet scan Modal-- > */}
            <div className="modal connect-modal fade" id="connect-wallet-scan" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <i className="bi bi-x"></i>
                            </button>
                        </div>
                        <div className="modal-body text-center">
                            <h1>Scan to connect</h1>
                            <div className="wallet-scan-img pt-3">
                                <img width="258" height="258" src="https://mwancloud.com/mw-najeeb-work/Connect-wallet/images/qr-img.svg" alt="qr-scan" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Sidebar
