export const utils = {

    convertTimeIntoLocal: (date: any) => {
        var mL = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const newdate = new Date(date)
        let month = mL[newdate?.getMonth()]
        let day = newdate?.getDate()
        let year = newdate?.getFullYear()
        let hours = newdate?.getHours()
        let suffix = hours >= 12 ? "PM" : "AM";
        let newHours = ((hours + 11) % 12 + 1)
        let min = newdate?.getMinutes()

        return { transactiondate: `${month} ${day}, ${year}`, transactionTime: `${newHours}:${min} ${suffix}` }


    }
}