import React, { useState } from "react";
import styled from "styled-components";

// import Coin from "https://mwancloud.com/mw-najeeb-work/Connect-wallet/images/icons/eth-coin.svg";
// import polygonCoin from "https://mwancloud.com/mw-najeeb-work/Connect-wallet/images/icons/polygon.png";
import { useEffect } from 'react';

const Main = styled("div")``;

const DropDownContainer = styled("div")`
  width: 10.5em;
  position:relative;`;

const DropDownHeader = styled("div")`
  margin-bottom: 5  px;
  padding: 12px 15px;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #2e2e2e;
  background: #f0f0f2;
  letter-spacing: 0.06em;
  cursor: pointer;
  text-transform: uppercase;
  & img {
    width: 24px;
    margin-right: 10px;
  }`;

const DropDownListContainer = styled("div")``;

const DropDownList = styled("ul")`
  margin: 0;
  box-shadow: 0px 2px 16px rgb(0 0 0 / 4%);
  padding: 5px;
  background: #ffffff;
  border: none;
  border-radius: 8px;
  box-sizing: border-box;
  color: #000;
  font-size: 16px;
  font-weight: 400;
  position: absolute;
  width: 100%;
  left: 0;
  &:first-child {
    // padding-top: 0.8em;
  }`;

const ListItem = styled("li")`
  list-style: none;
  margin-bottom: 0.8em;
  cursor: pointer;
  padding:10px;
  margin-bottom: 5px;
  border-radius: 8px;
  &:hover {
    background-color: #f0f0f2;
  }
  
  & img {
    width: 24px;
    margin-right: 10px;
  }`;

// const options = ["Ethereum", "Polygon"];


export default function Select({ options, selectElement, selectName }: any) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(selectName);
  const [img, setImg] = useState("https://mwancloud.com/mw-najeeb-work/Connect-wallet/images/icons/eth-coin.svg")


  useEffect(() => {
    onOptionClicked(options[0])
  }, [])

  const toggling = () => setIsOpen(!isOpen);




  const onOptionClicked = (value: any) => () => {
    setSelectedOption(value?.name);
    setImg(value?.image)
    setIsOpen(false);
    selectElement(value)
    // console.log(selectedOption);
  };

  return (
    <Main>
      <DropDownContainer>
        <DropDownHeader onClick={toggling}>
          {/* {selectedOption || "Ethereum"} */}
          <img src={img} />
          {selectedOption}
        </DropDownHeader>
        {isOpen && (
          <DropDownListContainer>
            <DropDownList>
              {options.map((option: any) => (
                <ListItem onClick={onOptionClicked(option)


                } key={Math.random()}>
                  {/* {selectedOption =="Ethereum" ? <img src={Coin}/>:null}   */}
                  <img src={option?.image} />
                  {option?.name}
                </ListItem>
              ))}
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
    </Main >
  );
}