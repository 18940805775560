import Contract from 'web3-eth-contract'
import onion1 from '../abi/onionAuction.json'
import sell1 from '../abi/sell.json'
import erc11551 from '../abi/erc1155.json'

import Common from 'ethereumjs-common';
import erc201 from '../abi/exs.json'
import utils from './utils'
import Web3 from 'web3'
import { ethers } from "ethers";
import axios from 'axios'
import WalletConnectProvider from "@maticnetwork/walletconnect-provider"
import { Transaction } from 'ethereumjs-tx'
import BigNumber from 'bignumber.js';
import Matic from "maticjs"
// import { buyNfts } from '../../components/buyScreen/api';

import erc1155poly from '../abi/erc1155poly.json'
import onionAuctionPoly from '../abi/onionAuctionpoly.json'
import erc20poly from '../abi/exspoly.json'
import erc20binance from '../abi/USDT_BINANCE.json'
import onionAuctionBinance from '../abi/onionAuction_FirstMarket.json'
import sellBinance from '../abi/sellContract_SecondMarket.json'



const currentNetwork = localStorage.getItem("currentNetwork") || "MUMBAI"

var erc1155 = null
var onion = null
var erc20 = null
var sell = sell1
var environement = `${process.env.REACT_APP_ENVIRONMENT}`
if (currentNetwork == "MUMBAI" && environement == "live") {
    erc1155 = erc1155poly
    erc20 = erc20poly
    onion = onionAuctionPoly
    sell = sell1
}
else {
    erc1155 = erc11551
    onion = currentNetwork == "BINANCE" && environement == "live" ? onionAuctionBinance : onion1
    erc20 = currentNetwork == "BINANCE" && environement == "live" ? erc20binance : erc201
    sell = currentNetwork == "BINANCE" && environement == "live" ? sellBinance : sell1

}







const Auction = {
    contract(Provider, addressContract, abi) {
        const contract = new Contract(abi, addressContract)
        contract.setProvider(Provider.provider)
        return contract
    },
    socketContract(Provider, contractInfo) {
        const contract = new Contract(onion['abi'], contractInfo.address)
        contract.setProvider(Provider.sockets[0])
        return contract
    },

    async calculateGasFee(usddata, cardId, account, nftdetail, tokens, onioncontract, setUsd, cb) {
        const data = usddata == null ? await axios?.get('https://min-api.cryptocompare.com/data/price?fsym=BNB&tsyms=USD,EUR') : usddata
        // .then(async function (data) {
        console.log(data);
        console.log(tokens)
        // console.log(onion1)

        if (data?.data != undefined) {
            var currentnetwork = localStorage.getItem("currentNetwork") || 'MUMBAI'
            var currentRPC = null
            if (currentnetwork == "MUMBAI") {
                currentRPC = `${process.env.REACT_APP_POLYGON_RPCURL}`
            }
            else if (currentnetwork == "BINANCE") {
                currentRPC = `${process.env.REACT_APP_BINANCE_PROVIDER}`
            } else {
                currentRPC = `${process.env.REACT_APP_ROPSTAN_PROVIDER}`

            }
            console.log(currentRPC)
            const web3 = new Web3('https://cloudflare-eth.com/');
            const erc20ContractAddress = localStorage.getItem("currentNetwork") != undefined ? localStorage.getItem("currentNetwork") == "MUMBAI" ? nftdetail?.currency == 'EXS' ? `${process.env.REACT_APP_MUMBAI_ER20}` : `${process.env.REACT_APP_USDT_POLY}` : nftdetail?.currency == 'EXS' ? `${process.env.REACT_APP_BINANCE_ER20}` : `${process.env.REACT_APP_USDT_BNB}` : nftdetail?.currency == 'EXS' ? `${process.env.REACT_APP_MUMBAI_ER20}` : `${process.env.REACT_APP_USDT_POLY}`
            console.log(erc20ContractAddress)
            const onionContractAddress = localStorage.getItem("currentNetwork") != undefined ? localStorage.getItem("currentNetwork") == "MUMBAI" ? `${process.env.REACT_APP_MUMBAI_ONION}` : `${process.env.REACT_APP_BINANCE_ONION}` : `${process.env.REACT_APP_MUMBAI_ONION}`
            console.log(onionContractAddress)
            const erc20Contract = new web3.eth.Contract(erc20['abi'], erc20ContractAddress)
            const contractAuction = new web3.eth.Contract(onion['abi'], onionContractAddress)
            const gasPrice = await web3.eth.getGasPrice()
            const estimated = await contractAuction.methods.buyCard(cardId, account)
                .estimateGas({ from: account })
            const txGasPrice = await web3.utils.fromWei(gasPrice.toString(), 'gwei');
            const txPriceWei = estimated * gasPrice;
            const txPriceEth = await web3.utils.fromWei(txPriceWei.toString(), 'ether');
            const txPriceUSD = txPriceEth * data?.data.USD;


            var token = tokens.find((x) => x.name == nftdetail.currency.toUpperCase())
            var decimals = tokens.find((x) => x.name == nftdetail?.currency.toUpperCase())?.decimals
            var amount = this.fromDecimals(parseFloat(nftdetail?.pricePerCard).toFixed(2) + 5, token)
            // var amount = 0
            const gasApprove = await erc20Contract.methods.approve(onioncontract.address, amount).estimateGas({ from: account })
            const txGasPriceApprove = await web3.utils.fromWei(gasPrice.toString(), 'gwei');
            const txPriceWeiApprove = gasApprove * gasPrice;
            const txPriceEthApprove = await web3.utils.fromWei(txPriceWeiApprove.toString(), 'ether');
            const txPriceUSDApprove = txPriceEthApprove * data?.data.USD;
            // processingLoad

            console.log(txPriceUSDApprove)
            console.log(txPriceUSD)
            const totalGas = txPriceUSDApprove + txPriceUSD
            console.log(totalGas)

            setUsd(data)
            cb(null, totalGas)
        } else {
            cb('something went Wrong', null)
        }



        // web3.eth.getGasPrice().then(gasPrice => {
        //     contractAuction
        //         .methods
        //         .buyCard(cardId, account)
        //         .estimateGas({ from: account })
        //         .then(estimatedGas => {
        //             const txGasPrice = web3.utils.fromWei(gasPrice.toString(), 'gwei');;
        //             const txPriceWei = estimatedGas * gasPrice;
        //             const txPriceEth = web3.utils.fromWei(txPriceWei.toString(), 'ether');
        //             const txPriceUSD = txPriceEth * data.USD;
        //             // const txPriceUSD = txPriceEth * 1800;
        //             console.log({
        //                 estimatedGas, txGasPrice, txPriceWei, txPriceEth, txPriceUSD
        //             });
        //             return {
        //                 estimatedGas, txGasPrice, txPriceWei, txPriceEth, txPriceUSD
        //             }
        //         });
        // })
        // }).catch(function (err) {
        //     console.warn('Something went wrong.', err);
        //     return null
        // });
    },
    /**
     * 
     * @param {Number} value Value Base
     * @param {Object} token token data to obtain decimals
     * @returns {Number} value converted to JS decimals
     */
    toDecimals(value, token) {

        const decimal = Math.pow(10, token.decimals) // {}*10**token.decimals
        const total = Number.parseFloat(value / decimal).toFixed(16) //please do not move this, as it maintains floating point precision.
        return total
    },
    /**
     * 
     * @param {Number} x Value Base
     * @param {Object} token token data to obtain decimals
     * @returns {Number} X converted to type solidity decimals
     */
    fromDecimals(x, token) {
        if (token.decimals == 18) {
            return utils.toWei(x)
        }
        const xInt = Math.pow(10, token.decimals)
        const xWei = x * xInt
        return xWei
    },

    async providerRPC(Provider, tokenAddress, abi) {
        // console.log(Provider)
        // console.log(tokenAddress);
        // console.log(abi);
        try {
            const contract = this.contract(Provider, tokenAddress, abi)
            return contract
        } catch (error) {
            console.log(error)
        }

    },
    /**
     * 
     * @param {String} addressContract address of the contract onionAcution
     * @param {Json} abi Interface Solidity Contract
     * @returns {Object} Contract call Object
     */
    mobileProvider(addressContract, abi) {
        const contract = new Contract(abi, addressContract)
        // console.log(addressContract)
        // console.log(abi);
        // console.log(`${process.env.REACT_APP_RPC}`);
        // console.log(contract);
        const link = localStorage.getItem("currentNetwork") != undefined ? localStorage.getItem("currentNetwork") == "MUMBAI" ? `${process.env.REACT_APP_MUMBAI_RPC}` : localStorage.getItem("currentNetwork") == "EHETERUM" ? `${process.env.REACT_APP_RINKEY_RPC}` : `${process.env.REACT_APP_BINANCE_RPC}` : `${process.env.REACT_APP_MUMBAI_RPC}`
        console.log(link)
        contract.setProvider(link)
        return contract
    },






    /**
     * Format from Solidity types to JS types
     * @param {Object} auction auction data with solidity types of the auctions
     * @param {Object} tokens cosntant to get data token
     * @returns {Object} a formated Object with JS types
     */
    formatAuctionData(auction, tokens) {
        // console.log(auction)
        // console.log(tokens)
        if (tokens != null && tokens != undefined) {
            const currency = utils.fromBytes32(auction._currency)
            const token = tokens.find((x) => x.name == currency.toUpperCase())
            if (token != undefined) {
                const pricePerCard = this.toDecimals(auction._pricePerCard, token)
                const incrementer = this.toDecimals(auction._incrementer, token)
                const auctionFormatted = {
                    auctioner: auction._owner,
                    tokenId: parseInt(auction._cardId),
                    currency: currency,
                    amount: auction._amountOnSale,
                    pricePerCard: pricePerCard,
                    incrementer: incrementer,
                    deadline: auction?._duration,
                    duration: auction?._deadline

                }
                return auctionFormatted
            } else {
                return {}
            }
        }
        else {
            const auctionFormatted = {
                auctioner: auction._owner,
                tokenId: parseInt(auction._cardId),

                amount: auction._amountOnSale,
                deadline: auction?._deadline
            }
            return auctionFormatted
        }
    },
    /**
     * 
     * @param {Object} contractInfo Constant data of the onionAuction
     * @param {Object} tokens cosntant to get data token
     * @returns {Array} Array formated of each auction avalaible.
     */
    async sellOnionAuction(tokens) {
        try {
            var currentNetworkAddress = null
            var currentnetwork = localStorage.getItem("currentNetwork") || 'MUMBAI'
            if (currentnetwork == "MUMBAI") {
                currentNetworkAddress = `${process.env.REACT_APP_SELLING_POLY}`
            }
            else if (currentnetwork == "BINANCE") {
                currentNetworkAddress = `${process.env.REACT_APP_SELLING_BNB}`
            } else {
                currentNetworkAddress = `${process.env.REACT_APP_SELLING_ETH}`
            }
            const sellcontract = await this.mobileProvider(currentNetworkAddress, sell['abi'])
            // return array with the auctionsID uint256[1,2,344,5]
            const sellAuctionIds = await sellcontract.methods.getAllUsersAuctionId().call()
            console.log(sellAuctionIds)
            const sellAuctionDetails = await sellcontract.methods.batchDetailsUserAuction(sellAuctionIds).call()
            var sellAuction = []
            console.log(sellAuctionDetails)
            sellAuctionDetails.forEach((auction, index) => {
                if (auction['_pricePerCard'] != 0) {
                    let formated = this.formatAuctionData(auction, tokens)
                    formated.onionId = sellAuctionIds[index]
                    formated.extradetails = auction
                    sellAuction.push(formated)
                }
            })
            console.log(sellAuction)
            return sellAuction
        } catch (e) {

        }
    },
    async onionAuctions(contractInfo, tokens) {
        try {
            const auctions = []
            const contract = await this.mobileProvider(contractInfo.address, onion['abi'])
            const batchAuctions = await contract.methods.getAllOnionsId().call()
            // console.log(batchAuctions);
            // return a solidity struct => js Object (_owner _athleteLoyalty _currency _cardId _amountOnSale _pricePerCard _incrementer;
            const auctionsDetails = await contract.methods.batchDetailsOnions(batchAuctions).call()
            console.log(auctionsDetails)
            auctionsDetails.forEach((auction, index) => {

                let formated = this.formatAuctionData(auction, tokens)
                formated.onionId = batchAuctions[index]
                formated.extradetails = auction
                auctions.push(formated)
            })
            console.log(auctions)
            return auctions
        } catch (error) {
            console.log(error)
        }


    },


    formatAuctionUpdated(auctionData, tokens) {
        // console.log(auctionData)
        const currentNetwork = localStorage?.getItem('currentNetwork') || "MUMBAI"
        const env = `${process.env.REACT_APP_ENVIRONMENT}`
        console.log(tokens)
        console.log(auctionData)
        const currency = currentNetwork == "MUMBAI" && env == "live" ? utils.fromBytes32(auctionData[2]) : utils.fromBytes32(auctionData[1])
        console.log(currency)
        const token = tokens.find((x) => x.name == currency.toUpperCase())
        const pricePerCard = this.toDecimals(auctionData[4], token)
        const incrementer = this.toDecimals(auctionData[5], token)
        return {
            amount: auctionData[3],
            pricePerCard: parseFloat(pricePerCard).toFixed(2),
            incrementer: parseFloat(incrementer).toFixed(2)
        }
    },
    async auction(onion, auction, tokens) {
        console.log(onion)
        console.log(auction)
        var auctionData = null
        if (auction?.onSell == false) {
            auctionData = await onion.methods.onionAuctionDetails(auction.onionId).call()
        }
        else {
            auctionData = await onion.methods.validAuctionDetails(auction.onionId).call()
        }
        // await onion.methods.userAuctionDetails(auction.onionId).call()
        console.log(this.formatAuctionUpdated)
        console.log(auctionData)
        var formated = this.formatAuctionUpdated(auctionData, tokens)
        console.log(formated)
        return formated
    },
    /**
     * 
     * @param {Object} Provider wallet provider
     * @param {String} address user Address
     * @param {Object} auction Auction to buy
     * @param {Object} onionContract constant Data of the Onion contract
     * @param {Object} tokens cosntant to get data token
     * @param {Object} dispatch 
     * @param {Object} popup state from 'notificationSystem' module
     * @returns {Boolean} if the transactions have been sent correctly, it returns true, 
     * but this does not mean that the purchase has been successful, 
     * since it is necessary to wait for the miners to validate the tx,
     *  and successfully add the tx's to the block.
     * you must have to weigh that the transaction appears as sent 
     * but in the blockchain it is reversed or sent and validated correctly.
     */
    async sendTokenWithExs(provider, amount, reciver, sender, tokenAddress, callback) {
        try {
            const web3 = new Web3(`${process.env.REACT_APP_ROPSTAN_PROVIDER}`)
            console.log(reciver)
            var count = await web3.eth.getTransactionCount(sender);
            console.log(count)
            var destAddress = reciver;
            var transferAmount = amount
            // var contractAddress = "0xDD5CBBfb58B1463611433b12d256DC30AE24BF42";
            var contractAddress = tokenAddress
            var contract = new web3.eth.Contract(erc20['abi'], contractAddress, {
                from: sender
            });
            console.log(contract)
            var balance = await contract.methods.balanceOf(sender).call();
            console.log(balance / 10 ** 18);
            var gasPriceGwei = 3;
            var gasLimit = 6000000;
            var chainId = 1;
            try {
                provider
                    .getSigner(sender)
                    .sendTransaction({
                        to: contractAddress,
                        gasPrice: web3.utils.toHex(gasPriceGwei * 1e9),
                        gasLimit: web3.utils.toHex(gasLimit),
                        from: sender,
                        nonce: "0x" + count.toString(16),
                        value: ethers.utils.parseEther("0.0"),
                        data: contract.methods.transfer(destAddress, transferAmount).encodeABI(),
                    })
                    .then((signature) => {
                        console.log(signature); // todo remove dev item
                        // setHash(signature.hash);
                        // window.alert(`Success!\n\n${signature}`)
                        callback(null, signature)
                    })
                    .catch((error) => {
                        // window.alert('Failure!' + (error && error.message ? `\n\n${error.message}` : ''))
                        console.log(error)
                        callback(error, null)
                    })
            } catch (e) {
                callback(e, null)
            }
        } catch (err) {
            console.log(err)
        }
    },
    async buynftwithwalletconnect(connector, account, auction, savetransaction, token, callback) {

        // New Changes for walletconnect
        const currentNetwork = localStorage.getItem("currentNetwork") != undefined ? localStorage.getItem("currentNetwork") == "MUMBAI" ? `${process.env.REACT_APP_POLYGON_RPCURL}` : `${process.env.REACT_APP_BINANCE_PROVIDER}` : `${process.env.REACT_APP_POLYGON_RPCURL}`
        console.log(currentNetwork)
        const web3 = new Web3(currentNetwork);
        const addressFrom = account;
        const addressTo = '0xb67C9263F86934461F9CB10468B72B15d9a9140A';
        const erc20ContractAddress = localStorage.getItem("currentNetwork") != undefined ? localStorage.getItem("currentNetwork") == "MUMBAI" ? auction?.currency == 'EXS' ? `${process.env.REACT_APP_MUMBAI_ER20}` : `${process.env.REACT_APP_USDT_POLY}` : auction?.currency == 'EXS' ? `${process.env.REACT_APP_BINANCE_ER20}` : `${process.env.REACT_APP_USDT_BNB}` : auction?.currency == 'EXS' ? `${process.env.REACT_APP_MUMBAI_ER20}` : `${process.env.REACT_APP_USDT_POLY}`
        console.log(erc20ContractAddress)
        const onionContractAddress = localStorage.getItem("currentNetwork") != undefined ? localStorage.getItem("currentNetwork") == "MUMBAI" ? `${process.env.REACT_APP_MUMBAI_ONION}` : `${process.env.REACT_APP_BINANCE_ONION}` : `${process.env.REACT_APP_MUMBAI_ONION}`
        console.log(onionContractAddress)
        const erc20Contract = new web3.eth.Contract(erc20['abi'], erc20ContractAddress)
        console.log(erc20Contract)
        const contractAuction = new web3.eth.Contract(onion['abi'], onionContractAddress)
        console.log(contractAuction)

        const chainId = localStorage.getItem("currentNetwork") != undefined ? localStorage.getItem("currentNetwork") == "MUMBAI" ? `${process.env.REACT_APP_POLYID}` : `${process.env.REACT_APP_BINANCEID}` : `${process.env.REACT_APP_POLYID}`
        var zerosToAdd = '000000000000000000'
        var decimal = 18
        if (localStorage.getItem("currentNetwork") == "MUMBAI") {
            zerosToAdd = auction?.currency == 'EXS' ? '000000000000000000' : "000000"
            decimal = auction?.currency == 'EXS' ? 18 : 6
        }
        else if (localStorage.getItem("currentNetwork") == "BINANCE") {
            zerosToAdd = '000000000000000000'
            decimal = 18
        }
        else {
            zerosToAdd = auction?.currency == 'EXS' ? '000000000000000000' : "000000"
            decimal = auction?.currency == 'EXS' ? 18 : 6
        }
        const price = parseFloat(auction?.auction?.pricePerCard)
        const newPrice = ethers.utils.parseUnits(price + zerosToAdd, decimal)
        console.log(auction?.auction?.pricePerCard)


        let extraData = await erc20Contract.methods.approve(onionContractAddress, newPrice)
        console.log(extraData)
        let data = extraData.encodeABI()
        const dataObj = {
            data: data,
            amount: parseFloat(auction?.auction?.pricePerCard)
        }
        let parsedata1 = JSON.stringify(dataObj)
        console.log(data)
        let extraData1 = await contractAuction.methods.buyCard(auction?.auction.onionId, addressFrom)
        console.log(extraData1)
        let data1 = extraData1.encodeABI()
        const dataObj1 = {
            data: data1,
            amount: parseFloat(auction?.auction?.pricePerCard)
        }
        let parsedata2 = JSON.stringify(dataObj1)
        console.log(data1)


        const web4 = new Web3("https://data-seed-prebsc-1-s3.binance.org:8545/");
        const customRequest = {
            id: 1337,
            jsonrpc: "2.0",
            method: "bnb_sign",
            "params": [
                {
                    "account_number": "34",
                    "chain_id": "97",
                    "data": parsedata1,
                    "memo": "test",
                    "msgs": [
                        {
                            "inputs": [
                                {
                                    "address": erc20ContractAddress,
                                    "coins": [
                                        {
                                            "amount": parseInt(auction?.auction?.pricePerCard),
                                            "denom": auction.currency,
                                        },
                                    ],
                                },
                            ],
                            "outputs": [
                                {
                                    "address": erc20ContractAddress,
                                    "coins": [
                                        {
                                            "amount": parseInt(auction?.auction?.pricePerCard),
                                            "denom": auction.currency,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                    "sequence": "31",
                    "source": "1",
                }
            ]
        }
        console.log("I am Here")
        connector.sendCustomRequest(customRequest).then((data) => {
            console.log(data)
            web3.eth.sendSignedTransaction(JSON.parse(data)?.signature).then((data) => {
                console.log(data)
                setTimeout(() => {
                    const customRequest1 = {
                        id: 1337,
                        jsonrpc: "2.0",
                        method: "bnb_sign",
                        "params": [
                            {
                                "account_number": "34",
                                "chain_id": "97",
                                "data": parsedata2,
                                "memo": "test",
                                "msgs": [
                                    {
                                        "inputs": [
                                            {
                                                "address": onionContractAddress,
                                                "coins": [
                                                    {
                                                        "amount": parseInt(auction?.auction?.pricePerCard),
                                                        "denom": auction.currency,
                                                    },
                                                ],
                                            },
                                        ],
                                        "outputs": [
                                            {
                                                "address": onionContractAddress,
                                                "coins": [
                                                    {
                                                        "amount": parseInt(auction?.auction?.pricePerCard),
                                                        "denom": auction.currency,
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                                "sequence": "31",
                                "source": "1",
                            }
                        ]
                    }
                    connector.sendCustomRequest(customRequest1).then((data) => {
                        console.log(data)
                        web3.eth.sendSignedTransaction(JSON.parse(data)?.signature)
                            .on('transactionHash', (data) => {
                                // buyNfts?.buyProduct({
                                //     ...savetransaction,
                                //     transaction: data,
                                //     status: 2

                                // }, token, (err, element) => { })
                            })
                            .once('receipt', (hash) => {
                                console.log(hash)
                                callback(null, hash?.transactionHash)
                            })
                            .on('error', err => {
                                console.log(err)
                                callback(err, null)
                            })
                    }).catch(err => {
                        console.log(err)
                        callback(err, null)
                    })
                }, 3000)

            }).catch((error) => {
                console.log(error)
                callback(error, null)
            })

        }).catch(err => {
            console.log(err)
            callback(err, null)

        })


    },

    async sendTokenWithWalletConnect(provider, amount, account, address, tokenAddress, decimals, currentCurrency, amount1, chainId, callback) {



        var currentNetwork = null
        if (chainId == 97 || chainId == 56) {
            currentNetwork = `${process.env.REACT_APP_BNB_PRC}`
        } else if (chainId == 80001 || chainId == 137) {
            currentNetwork = `${process.env.REACT_APP_POLY_PRC}`
        }
        else {
            currentNetwork = `${process.env.REACT_APP_ETH_RPC}`
        }
        console.log(currentNetwork)
        const web3 = new Web3(currentNetwork)
        // const erc20ContractAddress = `${process.env.REACT_APP_BINANCE_ER20}`
        const erc20ContractAddress = tokenAddress
        // const onionContractAddress = `${process.env.REACT_APP_BINANCE_ONION}`
        const onionContractAddress = localStorage.getItem("currentNetwork") != undefined ? localStorage.getItem("currentNetwork") == "MUMBAI" ? `${process.env.REACT_APP_MUMBAI_ONION}` : `${process.env.REACT_APP_BINANCE_ONION}` : `${process.env.REACT_APP_MUMBAI_ONION}`
        console.log(currentNetwork)
        console.log(tokenAddress)
        console.log(onionContractAddress)
        const erc20Contract = new web3.eth.Contract(erc20['abi'], erc20ContractAddress)
        console.log(erc20Contract)
        console.log(erc20ContractAddress)

        let extraData = await erc20Contract.methods.transfer(address, ethers.utils.parseUnits(amount))
        console.log(ethers.utils.parseUnits(amount, decimals))
        let data = extraData.encodeABI()
        console.log(data)
        let transferObject = {
            'data': data,
            'amount': parseFloat(amount1)
        }
        let data1 = JSON.stringify(transferObject)
        console.log(data1)
        try {

            const customRequest = {
                id: 1337,
                jsonrpc: "2.0",
                method: "bnb_sign",
                "params": [
                    {
                        "account_number": "34",
                        "chain_id": "97",
                        "data": data1,
                        "memo": "test",
                        "msgs": [
                            {
                                "inputs": [
                                    {
                                        "address": erc20ContractAddress,
                                        "coins": [
                                            {
                                                "amount": 0,
                                                "denom": currentCurrency,
                                            },
                                        ],
                                    },
                                ],
                                "outputs": [
                                    {
                                        "address": erc20ContractAddress,
                                        "coins": [
                                            {
                                                "amount": 0,
                                                "denom": currentCurrency,
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                        "sequence": "31",
                        "source": "1",
                    }
                ]
            }
            provider.sendCustomRequest(customRequest).then(async result => {
                console.log(result)
                web3.eth.sendSignedTransaction(JSON.parse(result)?.signature)
                    .once('receipt', (hash) => {
                        console.log(hash)
                        callback(null, hash?.transactionHash)
                    })
                    .on('error', (err) => {
                        callback(err, null)
                    })

            }).catch((err) => {
                console.log(err)
                callback(err, null)
            })

        } catch (error) {
            console.log(error)
            callback(error, null)
        }

        // End Wallet Connection Transfer Token
        // console.log(privatekey);
        // console.log(amount)
        // console.log(account)
        // console.log(address)
        // console.log(tokenAddress)
        // const sendCoins = true
        // try {
        //     const currentNetwork = localStorage.getItem("currentNetwork") != undefined ? localStorage.getItem("currentNetwork") == "MUMBAI" ? `${process.env.REACT_APP_POLYGON_RPCURL}` : `${process.env.REACT_APP_BINANCE_PROVIDER}` : `${process.env.REACT_APP_POLYGON_RPCURL}`
        //     const web3 = new Web3(currentNetwork); //https://bsc-dataseed1.binance.org:443
        //     const privKey =
        //         Buffer.from(privatekey, 'hex');
        //     // const addressFrom = '0xEbb897D6e990736a61b7Bd0d75b1FF20A605Cbf3';  //0xD99bF5801EAc1cDCe4CC873008E3eF254b5e894A
        //     const addressFrom = account;
        //     const addressTo = address;
        //     // const erc20ContractAddress = `${process.env.REACT_APP_BINANCE_ER20}`
        //     const erc20ContractAddress = tokenAddress
        //     const onionContractAddress = `${process.env.REACT_APP_BINANCE_ONION}`
        //     const erc20Contract = new web3.eth.Contract(erc20['abi'], erc20ContractAddress)
        //     console.log(erc20ContractAddress)
        //     const contractAuction = new web3.eth.Contract(onion['abi'], onionContractAddress)
        //     const zerosToAdd = '000000000000000000'
        //     web3.eth.getTransactionCount(addressFrom, async (err, txCount) => {
        //         const common = Common.forCustomChain('mainnet', {
        //             name: 'bnb',
        //             networkId: 97,
        //             chainId: 97
        //         }, 'petersburg');
        //         let extraData
        //         let data
        //         if (sendCoins) {
        //             extraData = await erc20Contract.methods.transfer(address, ethers.utils.parseUnits(amount, decimals))
        //             data = extraData.encodeABI()
        //         }
        //         console.log(txCount)
        //         console.log('Sending ....')
        //         const txObject = {
        //             nonce: web3.utils.toHex(txCount),
        //             from: addressFrom,
        //             to: erc20ContractAddress,
        //             value: "0x0",
        //             // gasLimit: web3.utils.toHex(21000),
        //             // gasPrice: web3.utils.toHex(web3.utils.toWei('10', 'gwei'))
        //             gasLimit: web3.utils.toHex(3000000),
        //             gasPrice: web3.utils.toHex(20000000000),
        //             data: data
        //         };
        //         // if (sendCoins) {
        //         //     txObject.to = contractObj.address,
        //         //         txObject.value = web3.utils.toHex(web3.utils.toWei('0', 'ether')),
        //         //         txObject.privKey = privKey
        //         //     txObject.data = data
        //         // }
        //         let tx = new Transaction(txObject, { "common": common });
        //         //const tx = new Tx(txObject,  {'chain':'56'});
        //         //const tx = new Tx(txObject,  {'chain':'binance'});
        //         tx.sign(privKey);
        //         const serializedTrans = tx.serialize();
        //         const raw = '0x' + serializedTrans.toString('hex');
        // web3.eth.sendSignedTransaction(raw)
        //     .once('receipt', (hash) => {
        //         console.log(hash)
        //         callback(null, hash)
        //     })
        //     .on('error', (err) => {
        //         callback(err, null)
        //     })
        //      (err, txHash) => {
        //     if (txHash) {
        //         console.log('Transaction Successfully : ', txHash)
        //     } else {
        //         console.log(err)
        //     }
        //     callback(null, txHash)
        // });
        //     }
        //     );
        // } catch (error) {
        //     console.log(error)
        //     callback(error, null)
        // }

    },
    async sendCustomTransaction(provider, address, amount, currency, chainId, cb) {
        console.log(amount)


        var currentNetwork = null
        if (chainId == 97 || chainId == 56) {
            currentNetwork = `${process.env.REACT_APP_BNB_PRC}`
        } else if (chainId == 80001 || chainId == 137) {
            currentNetwork = `${process.env.REACT_APP_POLY_PRC}`
        }
        else {
            currentNetwork = `${process.env.REACT_APP_ETH_RPC}`
        }
        console.log(currentNetwork)
        const web3 = new Web3(currentNetwork)

        try {

            const dataObj = {
                data: '',
                amount: web3.utils.toWei(amount, 'ether')
            }
            const customRequest = {
                id: 1337,
                jsonrpc: "2.0",
                method: "bnb_sign",
                "params": [
                    {
                        "account_number": "34",
                        "chain_id": "97",
                        "data": JSON.stringify(dataObj),
                        "memo": "test",
                        "msgs": [
                            {
                                "inputs": [
                                    {
                                        "address": address,
                                        "coins": [
                                            {
                                                "amount": Number(amount),
                                                "denom": `${currency}`
                                            },
                                        ],
                                    },
                                ],
                                "outputs": [
                                    {
                                        "address": address,
                                        "coins": [
                                            {
                                                "amount": Number(amount),
                                                "denom": `${currency}`
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                        "sequence": "31",
                        "source": "1",
                    }
                ]
            }
            const result = await provider.sendCustomRequest(customRequest);
            console.log(result)

            // const currentNetwork = "https://ultra-neat-sea.bsc-testnet.discover.quiknode.pro/1230442f7ee7cab7473fa53e11edbc698599af5a/"
            // const web3 = new Web3(currentNetwork)
            web3.eth.sendSignedTransaction(JSON.parse(result)?.signature).then((data) => {
                cb(null, data)
            })
                .catch((err) => {
                    cb(err, null)
                })


        } catch (error) {
            console.error(error);
            // this.setState({ connector, pendingRequest: false, result: null });
        }
    },
    // async getStatus(web, hash) {
    //     const result = await web.eth.getTransactionReceipt(hash)
    //     if (result == null) {
    //         this.getStatus(web, hash)
    //     }
    //     else {
    //         return result
    //     }
    // }
    // ,

    /**
     * 
     * @param {Object} Provider wallet provider
     * @param {String} address user Address
     * @param {Object} auction Auction to buy
     * @param {Object} onionContract constant Data of the Onion contract
     * @param {Object} tokens cosntant to get data token
     * @param {Object} dispatch 
     * @param {Object} popup state from 'notificationSystem' module
     * @returns {Boolean} if the transactions have been sent correctly, it returns true, 
     * but this does not mean that the purchase has been successful, 
     * since it is necessary to wait for the miners to validate the tx,
     *  and successfully add the tx's to the block.
     * you must have to weigh that the transaction appears as sent 
     * but in the blockchain it is reversed or sent and validated correctly.
     */


    callBack(err, result) {
        console.log(err, result)

        return true
    }

}

export default Auction