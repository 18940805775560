import axios from 'axios';

export const TIMEOUT = 15000;
const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    // baseURL: `http://127.0.0.1:3333/api/v1`,
    timeout: TIMEOUT
});
instance.interceptors.response.use((response: any) => response, (error: any) => {
    if (error.response.status == 401) {
        // message.error('User session expired',2)
        // window.location = '/login'

    }
    return error
})
export default instance;
