import '../../css/connect-wallet.css'
import './styles.css'
const ConnectWallet = ({ ExWalletConnect, walletdata }: any) => {
    return (
        <section className="main-body position-relative">

            <div className="main-content-container connect-wallet ps-0">

                <div className="home-body d-flex justify-content-center">
                    <div className="scan-connect text-center">
                        <div className="company-logo mb-3">
                            <img src="https://mwancloud.com/mw-najeeb-work/Connect-wallet/images/logo/mwanconnect-loog.svg" alt="logo" />
                        </div>
                        <div className="company-title mb-4 text-center">
                            <h1>Welcome to Mwan Connect</h1>

                        </div>
                        <button className="btn btn-primary rounded-pill mb-3 w-100" onClick={() => {
                            ExWalletConnect()
                        }}>
                            <span className="icon-connect me-2"></span> Connect Wallet
                        </button>
                    </div>
                </div>
                <div>

                    <svg className="waves waves-bottom" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
                        <defs>
                            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
                        </defs>
                        <g className="parallax">
                            <use xlinkHref="#gentle-wave" x="48" y="0" fill="#1f0077b3"></use>
                            <use xlinkHref="#gentle-wave" x="48" y="3" fill="#1f007780"></use>
                            <use xlinkHref="#gentle-wave" x="48" y="7" fill="#1f007780"></use>
                            <use xlinkHref="#gentle-wave" x="48" y="5" fill="#1f00774d"></use>
                            <use xlinkHref="#gentle-wave" x="48" y="7" fill="#1f007780"></use>
                        </g>
                    </svg>
                </div>

            </div>

        </section>

    )
}

export default ConnectWallet
